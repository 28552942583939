
<div id="list">
    <div id="channelSelector">
        <router-link v-for="channel in channels" :key="channel.shortname"
                     :to="{ name: 'List', params: { channel: channel.shortname }}"
                     :style="{borderColor:channel.primary_color}">
            <img :src="icon(channel.shortname)"
                 :alt="channel.stationname" :title="channel.stationname"
                 :class="channel.has_data?[]:['noData']">

        </router-link>
        <!--
        <audio controls>
            <source :src="channel.streamurl +';'" type="audio/mpeg">
        </audio>
        -->
    </div>
    <header v-if="channelData"
            :style="{backgroundColor:channelData.primary_color,color:channelData.secondary_color}">
        <h2 v-if="channelData">{{ channelData.stationname }}</h2>
        <div role="button" tabindex="0" v-on:click="toogleVisibility" v-on:keyup.enter="toogleVisibility">
            Meistgespielte Lieder {{ formatDate() }}
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 960 560" :class="showDate?[]:['disabled']">
                <path d="M480 344.181L268.869 131.889c-15.756-15.859-41.3-15.859-57.054 0-15.754 15.857-15.754 41.57 0 57.431l237.632 238.937c8.395 8.451 19.562 12.254 30.553 11.698 10.993.556 22.159-3.247 30.555-11.698L748.186 189.32c15.756-15.86 15.756-41.571 0-57.431s-41.299-15.859-57.051 0L480 344.181z"></path>
            </svg>
        </div>
    </header>
    <transition name="expand">
        <div id="date" class="customRow" v-if="showDate">
            <div>
                <datepicker :language="de" v-model="date" :mondayFirst="true" :inline="true"
                            :highlighted="highlighted"></datepicker>

                <div v-if="showMore" v-on:click="getMany" class="getMany">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 8 8">
                        <path d="M3 0v3h-2l3 3 3-3h-2v-3h-2zm-3 7v1h8v-1h-8z"></path>
                    </svg>
                </div>

            </div>
            <div>
                <input type="radio" id="day" value="day" v-model="dateType">
                <label class="label-inline" for="day">Tag</label>
                <br>
                <input type="radio" id="week" value="week" v-model="dateType">
                <label class="label-inline" for="week">Woche</label>
                <br>
                <input type="radio" id="month" value="month" v-model="dateType">
                <label class="label-inline" for="month">Monat</label>
                <br>
                <input type="radio" id="alltime" value="alltime" v-model="dateType">
                <label class="label-inline" for="alltime">Gesamter Zeitraum</label>
            </div>
        </div>
    </transition>
    <main>
        <div id="httpError" v-if="httpError" class="message">
            <strong>Beim Laden ist ein Fehler aufgetreten:</strong> {{ httpError.message }}
        </div>
        <div id="noData" class="message" v-if="(!channelData||!channelData.has_data)&&!httpError">
            <strong>Keine Daten!</strong> Leider gibt es für diesen Sender noch keine Daten.
        </div>
        <table>
            <template v-for="song in popular">
                <tr v-on:click="toogleDetails($event,song.song.id)" class="clickable">
                    <td>
                        <img v-if="song.song.image_small" :src="song.song.image_small">
                        <div v-else class="imgPlaceholder"></div>
                    </td>
                    <td>
                        {{ song.song.title }}
                        <router-link :to="{ name: 'DetailView',params:{channel:channel, songId:song.song.id} }"
                                     replace style="display: none;">{{ song.song.title }}
                        </router-link>
                    </td>
                    <td>{{ song.song.artist }}</td>
                    <td>{{ song.count }}</td>
                </tr>
                <tr v-if="parseInt($route.params.songId) === song.song.id">
                    <td colspan="4" class="detailWrapper">
                        <router-view :songs="songs"
                                     :color="{backgroundColor:channelData.primary_color,color:channelData.secondary_color}"
                                     :momentDate="momentDate" :dateType="dateType">
                            <!-- here the ItemModal component will be rendered -->
                        </router-view>
                    </td>
                </tr>
            </template>
        </table>
        <div id="loadMore" role="button" tabindex="0" v-on:click="getAdditional" v-on:keyup.enter="getAdditional"
             v-if="showMore &&channelData&&channelData.has_data">
            Mehr anzeigen
        </div>
    </main>

    <info v-if="channelData"
          :color="{backgroundColor:channelData.primary_color,color:channelData.secondary_color}"></info>
</div>
